import * as React from "react";
import { Edit, SimpleForm, TextInput, DateInput, Link, List, Datagrid, EditButton, DeleteWithConfirmButton } from 'react-admin';
import { FbDateField, FbDateRangeField } from "../components/FbDateField";

const TimesheetWeeksTitle = props => {
    return <span>{props.record?.production_reference ? `Timesheet Week ${props.record.production_reference}` : `Timesheet Week ${props.record.id}`}</span>;
};

const WeekReportButton = ({ record }) => {
    return (
        <Link to={`/timesheet-report/${record.id}`} style={{textTransform: "uppercase"}}>View Report</Link>
    );
};

const TimesheetWeekListDeleteButton = props => {

    const { record } = props;

    if (record.can_be_deleted) {
        return <DeleteWithConfirmButton {...props} />
    }

    return '';
};

export const TimesheetWeekList = props => (
    <List {...props}>
        <Datagrid>
            <FbDateRangeField startDateSource="start_date" endDateSource="end_date" extraSource="production_reference" label="" sortable={false} />
            <FbDateField source="generate_timesheets_date" label="Gen. TS On" sortable={false} />
            <FbDateField source="employee_reminder_date" label="Employee Reminder Date" sortable={false} />
            <FbDateField source="employee_missed_payroll_date" label="Employee Missed Payroll Date" sortable={false} />
            <FbDateField source="approval_reminder_date" label="Approval Reminder Date" sortable={false} />
            <FbDateField source="approval_missed_payroll_date" label="Approval Missed Payroll Date" sortable={false} />
            <WeekReportButton />
            <EditButton />
            <TimesheetWeekListDeleteButton />
        </Datagrid>
    </List>
);

export const TimesheetWeekEdit = props => {
    const transform = (data) => {
        delete data.end_date;
        delete data.start_date;
        return data;
    };

    return (
    <Edit {...props} title={<TimesheetWeeksTitle />} undoable={false} transform={transform}>
        <SimpleForm>
            <DateInput source="generate_timesheets_date" label="Generate Timesheets Date" />
            <TextInput source="production_reference" label="Production Reference" />
            <DateInput source="employee_reminder_date" label="Employee Reminder Date" />
            <DateInput source="employee_missed_payroll_date" label="Employee Missed Payroll Date" />
            <DateInput source="approval_reminder_date" label="Approval Reminder Date" />
            <DateInput source="approval_missed_payroll_date" label="Approval Missed Payroll Date" />
        </SimpleForm>
    </Edit>
    );
};
