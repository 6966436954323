import * as React from "react";
import { Edit, EditButton, DeleteButton, SimpleForm, TextInput, AutocompleteInput, ReferenceInput, SelectInput, required, Toolbar, SaveButton, TextField, Datagrid, List, Create, ReferenceField } from 'react-admin';

/** Toolbar that hides delete (use grid) */
const DepartmentApproversEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const DepartmentApproverTitle = props => {
    return <span>{Object.keys(props.record).length === 0 ? 'New Department Approver' : `${props.record.role_name} Stage - ${props.record.user.name}`}</span>;
};

export const DepartmentApproversList = props => {
    const filters = [
        <ReferenceInput alwaysOn source="department_id" reference="departments" perPage={100}>
            <SelectInput label="Department" source="id" optionText="name" />
        </ReferenceInput>,
    ];

    return <List {...props} filters={filters}>
        <Datagrid rowClick="edit">
            <ReferenceField source="department_id" reference="departments" sortable={false}>
                <TextField label="Department" source="name" />
            </ReferenceField>
            <TextField source="role_name" label="Role" sortable={false} />
            <TextField source="user.name" label="Name" sortable={false}  />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
}

export const DepartmentApproversEdit = props => {

    const transform = data => {
        data.user_id = data.user.id;
        delete data.user;
        delete data.department;
        return data;
    };

    return <Edit {...props} title={<DepartmentApproverTitle />} undoable={false} transform={transform}>
        <SimpleForm toolbar={<DepartmentApproversEditToolbar />} redirect="list">
            <TextInput source="role_name" label="Role" />
            <ReferenceInput label="User" source="user_id" reference="users" defaultValue={props.id} validate={[ required() ]}>
                <AutocompleteInput optionText="name" suggestionLimit="5" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
};

export const DepartmentApproversCreate = props => {

    const transform = data => {
        data.department_id = data.department.id;
        delete data.department;
        data.user_id = data.users.id;
        delete data.users;
        return data;
    };

    return <Create {...props} title={<DepartmentApproverTitle/>} transform={transform}>
        <SimpleForm toolbar={<DepartmentApproversEditToolbar/>} redirect="list">
            <ReferenceInput label="Department" source="department.id" reference="departments" validate={[ required() ]} perPage={100}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="role_name" label="Role" />
            <ReferenceInput label="User" source="users.id" reference="users" validate={[required()]}>
                <AutocompleteInput optionText="name" suggestionLimit="5"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
};
