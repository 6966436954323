import { useState } from "react";
import { useListContext, SelectInput, Filter, ListContextProvider, useQuery, ListView } from 'react-admin';

import { Table, TableBody } from '@material-ui/core';

import keyBy from 'lodash/keyBy';
import { WeeklyReportHeader, WeeklyReportRow } from "./components/WeeklyReportTable";

const ReportFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="Grouping" alwaysOn allowEmpty={false} source="group_by"
            choices={[
                { id: 'timesheet', name: 'None' },
                { id: 'status', name: 'Status' },
                { id: 'approver', name: 'Current Approver' },
                { id: 'department', name: 'Department' },
            ]}
        />
    </Filter>
);

const TimesheetReportDataGrid = (props) => {
    const { filter, filterValues, data, ids } = useListContext();
    const groupBy = filter.group_by ?? filterValues.group_by;
    const showFullTimesheet = !filter.department_id && !filter.status && !filter.approver_id && groupBy === 'timesheet';

    return (<>
        <WeeklyReportHeader groupBy={groupBy} depth={props.depth} record={data[ids[0]] ?? false} showFullTimesheet={showFullTimesheet} />
        {ids.map((id) => (
            <WeeklyReportRow key={id} groupBy={groupBy} record={data[id]} expand={props.expand} depth={props.depth} showFullTimesheet={showFullTimesheet} />
        ))}
    </>);
}

const TimesheetReportListContext = (props) => {
    const [page, setPage] = useState(1);
    const perPage = 50;
    // Default grouping: Department
    const [filter, setFilters] = useState(props.filter ?? { 'group_by': 'status' });

    const { data, total, loading, error } = useQuery({
        type: 'getList',
        resource: 'timesheet-report/' + props.week,
        payload: {
            filter: filter
        }
    });

    if (loading) {
        return <></>;
    }
    if (error) {
        return <p>ERROR: {error.toString()}</p>
    }

    const listProps = {
        basePath: '',
        actions: null,
        bulkActionButtons: false,
        filter,
        filterValues: filter,
        syncWithLocation: true,
        currentSort: { field: 'id', order: 'ASC' },
        data: keyBy(data, 'id'),
        ids: data.map(({ id }) => id),
        selectedIds: [],
        loading,
        error,
        total,
        page,
        perPage,
        setPage,
        setFilters,
        resource: props.week
    };

    return (<>
        <ListContextProvider value={listProps}>
            {props.children}
        </ListContextProvider>
    </>);
}

export const TimesheetReportShow = (props) => (
    <TimesheetReportListContext week={props.id}>
        <ListView filters={<ReportFilter />} pagination={false} title={" "} exporter={false}>
            <>
                <Table stickyHeader>
                    <TableBody>
                        <TimesheetReportDataGrid expand={TimesheetReportSubGroupList} depth={0} />
                    </TableBody>
                </Table>
            </>
        </ListView>
    </TimesheetReportListContext>
);

/**
 * Table which pops out from underneath the grouped main report rows to drill
 * down into more detail
 */
 const TimesheetReportSubGroupList = (props) => {
    let filter = {};

    // props.record here refers to the parent record that we're loading the sub-
    // data for, so if the parent grouping is department, we're loading a status
    // form, filtered by the parent's row's department ID
    if (props.record.current_grouping === 'department') {
        filter.group_by = 'status';
        filter.department_id = props.record.department_id;
    }

    if (props.record.current_grouping === 'status') {
        filter.group_by = 'department';
        filter.status = props.record.status_id;
    }

    if (props.record.current_grouping === 'approver') {
        if (props.record.approver_id) {
            filter.group_by = 'timesheet';
            filter.approver_id = props.record.approver_id;
        } else {
            filter.group_by = 'timesheet';
            filter.status = props.record.status_id;
        }
    }

    return (<>
        <TimesheetReportListContext {...props} filter={filter} week={props.record.timesheet_week_id}>
            <TimesheetReportDataGrid expand={TimesheetReportIndividualList} depth={1} />
        </TimesheetReportListContext>
    </>);
};

/**
 * Table which shows full list of individual timesheets
 */
 const TimesheetReportIndividualList = (props) => {
    const filter = {
        group_by: 'timesheet',
        department_id: props.record.department_id,
        status: props.record.status_id
    };

    return (<>
        <TimesheetReportListContext {...props} filter={filter} week={props.record.timesheet_week_id}>
            <TimesheetReportDataGrid depth={2} />
        </TimesheetReportListContext>
    </>);
};