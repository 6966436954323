import { createContext, useContext } from 'react';

export class ProductionProvider
{
    get production()
    {
        let production = localStorage.getItem('production');
        if (!production) {
            return false;
        }

        try {
            return(JSON.parse(production));
        } catch (e) {
            return false;
        }
    }

    set production(production)
    {
        if (typeof production === "object" && production !== null) {
            return localStorage.setItem('production', JSON.stringify(production));
        }
        return localStorage.removeItem('production');
    }
}

const defaultProvider = new ProductionProvider();
export const ProductionContext = createContext(defaultProvider);
export const useProductionProvider = () => useContext(ProductionContext);
