
const toLocaleStringSupportsLocales = (() => {
    // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
    try {
        new Date().toLocaleString('i');
    } catch (error) {
        return error instanceof RangeError;
    }
    return false;
})();

const getOrdinalNum = (number) => {
    if ((number > 3 && number < 21) || number % 10 > 3) {
      return 'th';
    }
    return  ['th', 'st', 'nd', 'rd'][number % 10];
};

export function formatIsoDate(value, showTime = false, locales = "en-GB", options = {'year': 'numeric', 'month': 'short', 'day': 'numeric'})
{
    if (typeof value !== "string" || value === "") {
        return "-";
    }
    const date = value instanceof Date ? value : new Date(value);
    const dateString = showTime
        ? toLocaleStringSupportsLocales
            ? date.toLocaleString(locales, options)
            : date.toLocaleString()
        : toLocaleStringSupportsLocales
        ? date.toLocaleDateString(locales, options)
        : date.toLocaleDateString();
    return dateString;
}

export function formatIsoDateRange(startDateVal, endDateVal, locales = 'en-GB')
{
    if (!toLocaleStringSupportsLocales) {
        return `${formatIsoDate(startDateVal)} - ${formatIsoDate(endDateVal)}`;
    }
    const startDate = startDateVal instanceof Date ? startDateVal : new Date(startDateVal);
    const startDateDay = startDate.toLocaleDateString(locales, {'day': 'numeric'});
    const startDateOrdinal = getOrdinalNum(startDateDay);
    const startDateMonth = startDate.toLocaleDateString(locales, {'month': 'short'});
    const startDateYear = startDate.toLocaleDateString(locales, {'year': 'numeric'});

    const endDate = endDateVal instanceof Date ? endDateVal : new Date(endDateVal);
    const endDateDay = endDate.toLocaleDateString(locales, {'day': 'numeric'});
    const endDateOrdinal = getOrdinalNum(endDateDay);
    const endDateMonth = endDate.toLocaleDateString(locales, {'month': 'short'});
    const endDateYear = endDate.toLocaleDateString(locales, {'year': 'numeric'});

    if (startDateYear !== endDateYear) {
        return `${startDateDay}${startDateOrdinal} ${startDateMonth} ${startDateYear} - ${endDateDay}${endDateOrdinal} ${endDateMonth} ${endDateYear}`;
    }
    if (startDateMonth !== endDateMonth) {
        return `${startDateDay}${startDateOrdinal} ${startDateMonth} - ${endDateDay}${endDateOrdinal} ${endDateMonth} ${startDateYear}`;
    }
    return `${startDateDay}${startDateOrdinal} - ${endDateDay}${endDateOrdinal} ${endDateMonth} ${startDateYear}`;
}