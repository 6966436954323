import { FunctionField, SelectInput } from "react-admin";
import { formatIsoDate, formatIsoDateRange } from "../functions/dateHelper";
import get from 'lodash/get';

export const formatRange = (startDateValue, endDateValue, extraValue) => {
    let formatted = formatIsoDateRange(startDateValue, endDateValue);
    if (typeof extraValue === 'string' && extraValue !== '') {
        return `${formatted} (${extraValue})`;
    }
    return formatted;
}

export const FbDateRangeSelectInput = ({startDateSource, endDateSource, extraSource, ...rest}) => {
    const renderRange = (record) => {
        return formatRange(get(record, startDateSource), get(record, endDateSource), get(record, extraSource));
    }

    return <SelectInput {...rest} source={startDateSource} optionText={renderRange} />
}

export const FbDateRangeField = ({startDateSource, endDateSource, extraSource, ...rest}) => {
    const renderRange = (record) => {
        return formatRange(get(record, startDateSource), get(record, endDateSource), get(record, extraSource));
    }

    return <FunctionField {...rest} source={startDateSource} render={renderRange} />
}

export const FbDateField = (props) => (
    <FunctionField {...props} render={(record, source) => formatIsoDate(record[source])} />
)