import * as React from "react";
import { Edit, SimpleForm, TextInput, List, Datagrid, TextField, DateField, Create, Toolbar, SaveButton } from 'react-admin';

const PenaltyTypesTitle = props => {
    return <span>{props.record ? `${props.record.full_name}` : ''}</span>;
};

/** Toolbar that hides delete (use grid) */
const PenaltyTypesEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const PenaltyTypesList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="code" />
            <TextField source="short_name" />
            <TextField source="full_name" />
        </Datagrid>
    </List>
);

export const PenaltyTypesEdit = props => {
    const transform = (record) => {
        //delete record.production_id;
        return record;
    };

    return <Edit {...props}  undoable={false} title={<PenaltyTypesTitle />} transform={transform}>
        <SimpleForm redirect="list" toolbar={<PenaltyTypesEditToolbar />}>
            <TextInput source="code" />
            <TextInput source="short_name" />
            <TextInput source="full_name" />
        </SimpleForm>
    </Edit>
};