import * as React from "react";
import { Edit, EditButton, DeleteButton, SimpleForm, NumberInput, ReferenceInput, SelectInput, required, Toolbar, SaveButton, TextField, Datagrid, List, Create } from 'react-admin';

/** Toolbar that hides delete (use grid) */
const ProductionApprovalStagesEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const ProductionApprovalStageTitle = props => {
    return <span>{Object.keys(props.record).length === 0 ? 'New Approval Stage':  `Stage ${props.record.stage}`}</span>;
};

export const ProductionApprovalStagesList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="stage" label="Stage #" />
            <TextField source="approver.role_name" label="Responsible Role" />
            <TextField source="approver.user.name" label="Responsible Person" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const ProductionApprovalStagesEdit = props => {

    const transform = data => {
        data.production_approver_id = data.approver.id;
        delete data.approver;
        return data;
    };

    return <Edit {...props} title={<ProductionApprovalStageTitle />} undoable={false} transform={transform}>
        <SimpleForm toolbar={<ProductionApprovalStagesEditToolbar />} redirect="list">
            <ReferenceInput label="User" source="approver.id" reference="production-approvers" validate={[ required() ]}>
                <SelectInput optionText={ (record) => `${record.user.name} (${record.role_name})` } />
            </ReferenceInput>
            <NumberInput label="Stage" source="stage" validate={[ required() ]}/>
        </SimpleForm>
    </Edit>
};

export const ProductionApprovalStagesCreate = props => {

    const transform = data => {
        data.production_id = props.match.params.productionId;
        data.production_approver_id = data.approver.id;
        delete data.approver;
        return data;
    };

    return <Create {...props} title={<ProductionApprovalStageTitle/>} transform={transform}>
        <SimpleForm toolbar={<ProductionApprovalStagesEditToolbar/>} redirect="list">
            <ReferenceInput label="User" source="approver.id" reference="production-approvers" validate={[required()]}>
                <SelectInput optionText={ (record) => `${record.user.name} (${record.role_name})` } />
            </ReferenceInput>
            <NumberInput label="Stage" source="stage" validate={[required()]}/>
        </SimpleForm>
    </Create>
};
