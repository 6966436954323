import config from './config.js';

export default class AuthProviderClass
{
    constructor(appUrl, productionProvider) {
        this.appUrl = appUrl;
        this.productionProvider = productionProvider;
    }

    get token() {
        return localStorage.getItem('auth');
    }

    login({ token, production }) {
        localStorage.setItem('auth', token);
        this.productionProvider.production = production;
        return Promise.resolve();
    }

    checkError(error) {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    }

    checkAuth() {
        return localStorage.getItem('auth')
        ? Promise.resolve()
        : Promise.reject({
            // Disable "please login" popup
            message: false
        });
    }

    logout(params) {
        localStorage.removeItem('auth');
        this.productionProvider.production = null;
        if (params === null) {
            // logout was pressed, it specifically sends null as the param,
            // let's go back to the app
            window.location = config.appUrl;
            return Promise.resolve();
        } else {
            // think we're in a check - go to login and specify no error message
            return Promise.resolve();
        }
    }

    getPermissions() {
        return localStorage.getItem('auth')
        ? Promise.resolve('admin')
        : Promise.reject();
    }
}