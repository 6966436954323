import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    TextInput,
    useDataProvider,
    useNotify,
    useRefresh
} from 'react-admin';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from "react-final-form";


const useStyles = makeStyles({
    root: { width: 600, margin: '30px auto' },
    spacer: { height: 20 },
    table: { margin: '10px 0' },
    saveButton: { margin: '0 30px 0 0' }
});

export const EmploymentUpdateRequestList = props => (

    <List
        {...props}
        bulkActionButtons={false}
        exporter={false}
        perPage={5}
        sort={{ field: 'created_at', order: 'desc' }}
        resource="employment-update-requests"
        basePath="employment-update-requests"
        filter={{ employment_id: props.id }}
        title={<></>}
    >
        <Datagrid rowClick="expand" expand={<ShowEmploymentUpdateRequest />}>
            <TextField source="id" label="ID" />
            <TextField source="user.name" label="Requested By" />
            <TextField source="nice_status" label="Status" />
            <TextField source="notes" label="Notes" sortable={false} />
            <DateField source="created_at" label="Requested At" showTime />
        </Datagrid>
    </List>
);

export const ShowEmploymentUpdateRequest = props => {

    let statusId = 1;

    let formData = {
        notes: props.record.notes,
    };

    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();

    const dataProvider = useDataProvider();

    const onSubmit = async (values) => {
        console.log('onSubmit');
        console.log(JSON.stringify(values, 0, 2));

        dataProvider.update(
            `employment-update-requests`,
            { id: props.record.id, data: {notes: values.notes, status_id: statusId }}
        ).then((response) => {
            console.log(response)
            notify('Employment Update Request Approved', 'success');
            refresh();
        }).catch((response) => {
            if (typeof response.body !== 'undefined' && response.body.message) {
                notify(response.body.message, 'error');
            } else if (typeof response.body !== 'undefined') {
                notify(JSON.stringify(response.body), 'error'); // lazy
            } else {
                console.error(response);
            }
        })

    };

    const approve = () => {
        console.log('approve');
        statusId = 2;
    };

    const reject = () => {
        console.log('reject');
        statusId = 3;
    };

    if (props.record.status_id === 1) {
        return (
            <Card className={classes.root}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                {props.record.employment.first_name} {props.record.employment.surname}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom align="right">
                                Update Request #{props.id}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.spacer}>&nbsp;</div>
                    <div className={classes.table}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Field
                                    </TableCell>
                                    <TableCell>
                                        Current Value
                                    </TableCell>
                                    <TableCell>
                                        Requested Change
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.record.requested_changes.map(
                                    (attribute) =>
                                        <TableRow>
                                            <TableCell>{attribute.nice_name}</TableCell>
                                            <TableCell>{attribute.current_value}</TableCell>
                                            <TableCell>{attribute.new_value}</TableCell>
                                        </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <div className={classes.spacer}>&nbsp;</div>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={{
                            ...formData,
                        }}
                        render={({handleSubmit, form, submitting, pristine, values}) => (
                            <form onSubmit={handleSubmit}>
                                <TextInput multiline source="notes"/>
                                <div className={classes.spacer}>&nbsp;</div>
                                <button type="submit" disabled={submitting} onClick={approve}
                                        className={`${classes.saveButton} MuiButtonBase-root MuiButton-root MuiButton-contained RaSaveButton-button-72 makeStyles-saveButton-164 MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall`}>
                                    <span className="MuiButton-label">
                                        <svg
                                            className="MuiSvgIcon-root RaSaveButton-leftIcon-73 RaSaveButton-icon-74"
                                            focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                                        >
                                            <path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"></path>
                                        </svg>
                                        Approve
                                    </span>
                                </button>

                                <button type="submit" disabled={submitting} onClick={reject} className="MuiButtonBase-root MuiButton-root MuiButton-text RaSaveButton-button-72 MuiButton-textSecondary MuiButton-textSizeSmall MuiButton-sizeSmall">
                                    <span className="MuiButton-label">
                                        <svg
                                            className="MuiSvgIcon-root RaSaveButton-leftIcon-73 RaSaveButton-icon-74"
                                            focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                                        >
                                            <path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm4 0v12h4V3h-4z"></path>
                                        </svg>
                                        Reject
                                    </span>
                                </button>
                            </form>
                        )}
                    />
                </CardContent>
            </Card>
        );
    } else {
        return (
            <Card className={classes.root}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                {props.record.employment.first_name} {props.record.employment.surname}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom align="right">
                                Update Request #{props.id}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.spacer}>&nbsp;</div>
                    <div className={classes.table}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Field
                                    </TableCell>
                                    <TableCell>
                                        Current Value
                                    </TableCell>
                                    <TableCell>
                                        Requested Change
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.record.requested_changes.map(
                                    (attribute) =>
                                        <TableRow>
                                            <TableCell>{attribute.nice_name}</TableCell>
                                            <TableCell>{attribute.current_value}</TableCell>
                                            <TableCell>{attribute.new_value}</TableCell>
                                        </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <div className={classes.spacer}>&nbsp;</div>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography gutterBottom>
                                Status: {props.record.nice_status}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutterBottom align="right">
                                Actioned By: {props.record.actioned_by.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
};