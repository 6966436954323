import React, { useCallback, useMemo, useState } from 'react';
import { SaveContextProvider, Toolbar, SaveButton, SimpleForm, FileInput, FileField, required, useDataProvider, useNotify, useRedirect } from 'react-admin';
import DownloadEmploymentsTemplateButton from './DownloadEmploymentsTemplateButton';

const ProductionEmploymentsImportForm = props => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const [saving, setSaving] = useState();
    const [importError, setImportError] = useState();

    const handleFail = useCallback((a) => {
        notify('Unknown error, see console', 'error');
        console.log(a);
    }, [notify]);

    const handleSave = useCallback((values) => {
        setSaving(true);
        dataProvider.createMultipart(
            `employments-import`,
            { data: values }
        ).then(() => {
            setSaving(false);
            setImportError(false);
            notify("Uploaded successfully", 'success');
            redirect(`/employees`);
        }).catch((error) => {
            console.log(error);
            setSaving(false);
            if (error.body.errors) {
                setImportError(error.body);
            } else {
                notify(JSON.stringify(error.body));
            }
        })
      }, [dataProvider, notify, redirect]);

    const saveContext = useMemo(() => ({
        save: handleSave,
        setOnFailure: handleFail,
        saving
    }), [saving, handleSave, handleFail]);

    const ImportErrorRow = ({ error }) => (
        <tr>
            <td>{error.row} ({error.values.first_name} {error.values.surname})</td>
            <td>{error.attribute}</td>
            <td>{error.errors}</td>
        </tr>
    );
    const ImportErrorList = () => (<>
        <p>Errors were encountered when processing this spreadsheet.</p>
        <table style={{"width": "100%"}}>
            <thead>
                <tr>
                    <th>Row No.</th>
                    <th>Field</th>
                    <th>Error Message</th>
                </tr>
            </thead>
            <tbody>
                { importError.errors.map((error) => (<ImportErrorRow error={error}/>)) }
            </tbody>
        </table>
    </>);

    let errorOutput = (<></>);
    if (importError) {
        errorOutput = (<ImportErrorList/>);
    }

    const ImportToolbar = props => (
        <Toolbar {...props} >
            <SaveButton
                label="Import Production Employments"
                redirect={false}
                submitOnEnter={false}
                variant="text"
            />
        </Toolbar>
    );

    return (<>
        {errorOutput}
        <SaveContextProvider value={saveContext}>
            <DownloadEmploymentsTemplateButton />
            <SimpleForm toolbar={<ImportToolbar />} save={handleSave}>
                <FileInput source="import" label="Upload file (.xlsx)" multiple={false} accept=".xlsx" validate={required()}>
                    <FileField source="src" title="title" />
                </FileInput>
            </SimpleForm>
        </SaveContextProvider>
    </>);

};

export default ProductionEmploymentsImportForm;
