
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { createRef, memo, useEffect } from 'react';
import { AppBar, Layout } from 'react-admin';
import { Menu } from './Menu';
import { useProductionProvider } from "./ProductionContext";

const styles = {
    'title': {
        flex: 1
    },
    'prodName': {
        display: 'inline-block',
        '.dash &::after': {
            content: '"-"',
            display: 'inline',
            margin: '0 0.4em'
        }
    }
};

const ProductionName = ({classes}) => {
    const { production } = useProductionProvider();
    return <Typography
        variant="h6"
        color="inherit"
        className={classes.prodName}
    >{ production.name }</Typography>;
}

const Title = withStyles(styles)(ProductionName);
// Memo stops it being redrawn when other elements are rendered, so the ref into
// the title element still works.
const AppBarWithTitle = memo((props) => {
    const { title, children, ...rest } = props;
    const titleEl = createRef();
    useEffect(() => {
        if (titleEl.current instanceof Node) {
            const observer = new MutationObserver((mutations) => {
                // Set window title
                if (mutations.length > 0 && titleEl.current instanceof Node) {
                    const fullTitle = titleEl.current.innerText.replace("\n", " - ");
                    document.title = `${fullTitle} | Film Beans`;
                    // Toggle dash in title bar
                    if (fullTitle !== titleEl.current.innerText) {
                        titleEl.current.parentElement.classList.add('dash')
                    } else {
                        titleEl.current.parentElement.classList.remove('dash')
                    }
                }
            });
            observer.observe(titleEl.current, {attributes: true, childList: true, characterData: true, subtree: true});
        }
    });
    return <AppBar {...rest}>
        <div style={{
            display: 'flex',
            flex: '1 1 0%'
        }} ref={titleEl}>
            <Title />
            <Typography
                variant="h6"
                color="inherit"
                id="react-admin-title"
                ref={titleEl}
            />
        </div>
    </AppBar>;
});

export const FbLayout = (props) => {
    const { appBar, ...rest } = props;
    return <Layout
        {...rest}
        menu={Menu}
        appBar={AppBarWithTitle}
    />;
}