import * as React from "react";
import {
    Datagrid,
    TextField,
    ListContextProvider,
    useNotify,
    useDataProvider,
    required,
    SimpleForm,
    NumberInput,
    number,
    minValue,
    maxValue,
    DateInput,
    Toolbar,
    Button,
    useRedirect,
    BooleanField,
    Title
} from 'react-admin';
import { useQuery } from "react-admin";

import { Paper, Container } from '@material-ui/core';
import ContentSave from '@material-ui/icons/Save';

import { FormSpy } from 'react-final-form';

import keyBy from 'lodash/keyBy';

const TableSpy = ({ values, valid, validating, pristine }) => {
    // TableSpy element checks whether we have data we can submit to the API.
    // If we don't it outputs an empty table (DummyDataTable component).
    if (validating || !valid || pristine) {
        return (<DummyDataTable/>);
    } else {
        return (<ExampleDataTable values={values} />);
    }
}

// ProposedDataTable component is the table of proposed data.
const ProposedDataTable = ({data}) => (
    <ListContextProvider
    value={{
        basePath: 'user',
        data: keyBy(data, 'id'),
        ids: data.map(({ id }) => id),
        currentSort: { field: 'id', order: 'ASC' },
        selectedIds: [],
        resource: 'user'
    }}
    >
        <Datagrid>
            <TextField name="start_date" source="start_date" sortable={false} />
            <TextField source="end_date" sortable={false} />
            <TextField source="generate_timesheets_date" sortable={false} />
            <TextField source="employee_reminder_date" sortable={false} />
            <TextField source="employee_missed_payroll_date" sortable={false} />
            <TextField source="approval_reminder_date" sortable={false} />
            <TextField source="approval_missed_payroll_date" sortable={false} />
            <BooleanField source="exists" label="Exists?" />
        </Datagrid>
    </ListContextProvider>
);

// DummyDataTable is a stub which just passes some fake data to ProposedDataTable
// so our empty table looks a bit neater.
const DummyDataTable = () => {
    const data = [{
        "id": "0",
        "start_date": "-",
        "end_date": "-",
        "generate_timesheets_date": "-"
    }];
    return (
        <ProposedDataTable data={data} />
    );
};

// ExampleDataTable fetches proposal data, displays it, and then saves that
// proposal to the database when the save button is pressed.
const ExampleDataTable = ({ values }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    values.start_date = new Date(values.start_date).toISOString().slice(0, 10);

    const { data, loading, error } = useQuery({
        type: 'getList',
        resource: 'timesheet-weeks/proposal',
        payload: {
            pagination: {},
            sort: {},
            filter: values,
        }
    });

    if (loading) {
        return <DummyDataTable />
    }
    if (error) {
        return <p>ERROR: {error.message}</p>
    }

    const saveList = () => {

        const existingWeeks = data.filter(week => week.exists);
        const newWeeks = data.filter(week => !week.exists);

        let successCount = 0;

        if (existingWeeks.length > 0) {
            notify(`${existingWeeks.length} weeks already exist and will not be created`, 'warning');
        }

        var creations = [];
        newWeeks.forEach(proposal => {
            delete proposal.exists;
            delete proposal.id;

            creations.push(dataProvider.create(
                `timesheet-weeks`, {
                    data: proposal
                }
            ).then(() => {
                successCount++;
            }));
        });

        Promise.all(creations).then(() => {
            if (successCount === newWeeks.length) {
                if (newWeeks.length === data.length) {
                    // all weeks were added
                    notify('Timesheet weeks added', 'success');
                    return redirect(`/timesheet-weeks`);
                } else if (newWeeks.length === 0) {
                    notify('No new weeks were added', 'error');
                    // No redirect, probably something went wrong
                } else {
                    // a mixture
                    notify(`${newWeeks.length} ${newWeeks.length === 1 ? 'week' : 'weeks'} added`, 'success');
                    return redirect(`/timesheet-weeks`);
                }
            }
        });
    }

    return (
        <>
            <ProposedDataTable data={data}/>
            <Toolbar>
                <Button
                    variant="contained"
                    onClick={saveList}
                    label="Save"
                    title="Save"
                >
                    <ContentSave />
                </Button>
            </Toolbar>
        </>
    );
}

// The form we display to the user, which takes proposal parameters. A FormSpy
// is used to convert those into something we can post to the creation endpoint
export const GenerateTimesheetWeeksForm = props => {
    const notify = useNotify();

    const lastMonday = () => {
        var date = new Date();
        return date.setDate(date.getDate() - date.getDay() - 6);
    };

    const checkIsMonday = (value) => {
        const date = new Date(value);

        // Monday being 1
        if (date.getDay() !== 1) {
            notify('The start date is not a Monday', 'warning');
        }
    }

    return (
        <Container component={Paper}>
            <Title title="Generate Weeks" />
            <h1>Generate Weeks</h1>
            <SimpleForm toolbar={null}>
                <DateInput source="start_date" validate={[ required() ]} defaultValue={lastMonday()} onChange={event => checkIsMonday(event.target.value)} />
                <NumberInput source="number_of_weeks" validate={[ required(), number(), maxValue(100) ]} />
                <p>
                    How many days before the start date should the timesheet be visible?
                </p>
                <NumberInput
                    min="0" max="30"
                    source="when_to_generate"
                    validate={[ number(), minValue(0), maxValue(30), required() ]}
                />
                <p>
                    How many days after the end date will the employee be considered to have missed the payroll deadline?
                </p>
                <NumberInput
                    min="0" max="30"
                    source="employee_missed_payroll_offset"
                    validate={[ number(), minValue(0), maxValue(30), required() ]}
                />
                <p>
                    How many days after the end date will the approver be considered to have caused the payroll deadline to be missed?
                </p>
                <NumberInput
                    min="0" max="30"
                    source="approver_missed_payroll_offset"
                    validate={[ number(), minValue(0), maxValue(30), required() ]}
                />
                <p>
                    How many days after the end date should a reminder be sent to the employee to submit the timesheet?
                </p>
                <NumberInput
                    min="0" max="30"
                    source="employee_reminder_offset"
                    validate={[ number(), minValue(0), maxValue(7), required() ]}
                />
                <p>
                    How many days after the end date should a reminder be sent to the approver to check the timesheet?
                </p>
                <NumberInput
                    min="0" max="30"
                    source="approver_reminder_offset"
                    validate={[ number(), minValue(0), maxValue(7), required() ]}
                />
                <FormSpy subscription={{ values: true, valid: true, validating: true, pristine: true }} component={TableSpy} />
            </SimpleForm>
        </Container>
    );
};
