import * as React from "react";
import { List, Datagrid, TextField, SelectInput, ReferenceInput } from 'react-admin';
import { FbDateField, FbDateRangeField } from "../components/FbDateField";
import { formatIsoDateRange } from "../functions/dateHelper";

export const TimesheetsList = props => {
    const filters = [
        <ReferenceInput alwaysOn source="department_id" reference="departments" perPage={100}>
            <SelectInput label="Department" source="id" optionText="name" />
        </ReferenceInput>,
        <ReferenceInput alwaysOn source="timesheet_week_id" reference="timesheet-weeks" perPage={100}>
            <SelectInput label="Week" source="id" optionText={(record) => formatIsoDateRange(record.start_date, record.end_date)} />
        </ReferenceInput>,
        <SelectInput label="Status" source="status" alwaysOn choices={[
            { id: 'approved', name: 'Approved' },
            { id: 'created', name: 'Created' },
            { id: 'submitted', name: 'Submitted' },
            { id: 'rejected', name: 'Rejected' },
        ]} />
    ];

    return <List {...props} filters={filters} bulkActionButtons={false}>
        <Datagrid>
            <FbDateRangeField label="Timesheet Week" startDateSource="week_start_date" endDateSource="week_end_date" extraSource="week_reference" />
            <TextField sortable={false} source="department_name" label="Department" />
            <TextField sortable={false} source="employment_name" label="Employment" />
            <TextField sortable={false} source="employment_job_title" label="Job Title" />
            <TextField sortable={false} source="status" label="Status" />
            <FbDateField sortable={false} source="approval_submitted_at" label="Approval Submitted At" />
            <TextField sortable={false} source="approval_submitted_by_name" label="Approval Submitted By" emptyText="-" />
            <FbDateField sortable={false} source="approved_at" label="Approved At" />
            <FbDateField sortable={false} source="reminder_sent" label="Reminder Sent" />
        </Datagrid>
    </List>
};
