import * as React from "react";
import {
    Edit,
    FormWithRedirect,
    TextInput,
    Toolbar,
    SaveButton,
    ReferenceInput,
    SelectInput,
    DateInput,
    ResourceContextProvider,
    BooleanInput,
    List,
    DeleteButton,
    EditButton,
    TextField,
    Datagrid,
    useDataProvider,
    Button,
    Create,
    SimpleForm,
    useMutation,
    useRedirect,
    useNotify
} from 'react-admin';

import { Typography, Box, Tabs, Tab } from '@material-ui/core';

import { EmploymentUpdateRequestList } from './employment-update-requests';
import { AddEmploymentUsers, EmploymentUsersList } from "./employment-users";


import GetAppIcon from "@material-ui/icons/GetApp";
import { downloadGetFileResult } from "../functions/downloadGetFileResult";
import ProductionEmploymentsImportForm from "./components/ProductionEmploymentsImportForm";

const EmploymentStartFormDownloadButton = ({ record }) => {

    const dataProvider = useDataProvider();

    const downloadStartForm = () => dataProvider.getFile(
        `employees/${record.id}/start-form-pdf`
    ).then(downloadGetFileResult)

    if (!record.is_onboarded) {
        return '';
    }

    return <Button
        variant="contained"
        to={`employments/${record.id}/start-form-pdf`}
        label="Start Form"
        title="Download start form"
        onClick={downloadStartForm}
    >
        <GetAppIcon />
    </Button>
};

const EmploymentTitle = props => {
    return <span>{props.record ? `${props.record.first_name} ${props.record.surname} - ${props.record.department.name}` : ''}</span>;
};

const filters = [
    <TextInput label="Search" source="search" alwaysOn />,
    <ReferenceInput perPage="100" label="Department" source="department_id" reference="departments" emptyText="All" resettable={true} alwaysOn>
        <SelectInput optionText="name" />
    </ReferenceInput>,
];

export const EmploymentsList = props => (
    <List {...props} filters={filters} sort={{ field: 'surname', order: 'asc' }}>
        <Datagrid>
            <TextField source="first_name" label="First Name" />
            <TextField source="surname" label="Surname" />
            <TextField source="department.name" label="Department" />
            <TextField source="job_title" label="Job Title" />
            <EmploymentStartFormDownloadButton label="Start Form" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
)

export const EmploymentsEdit = props => {
    const transform = (data) => {
        delete data.production_id;
        delete data.department;
        return data;
    };

    return (
        <Edit {...props} title={<EmploymentTitle />} undoable={false} transform={transform}>

            <FormWithRedirect
                render={formProps => (

                    // here starts the custom form layout
                    <Box p="1em">
                        <Box display="flex">
                            <Box flex={1} mr="1em">
                                <form>

                                    <Typography variant="h6" gutterBottom>Employment Details</Typography>

                                    <TextInput source="first_name" fullWidth />
                                    <TextInput source="surname" fullWidth />
                                    <ReferenceInput label="Department" source="department_id" reference="departments" fullWidth filter={{ production_id: formProps.record.production_id }} perPage={100}>
                                        <SelectInput optionText="name" optionValue="id" />
                                    </ReferenceInput>
                                    <TextInput source="job_title" fullWidth />
                                    <TextInput source="phone" fullWidth />
                                    <SelectInput source="contract_type" choices={[
                                        { id: 'daily', name: 'Daily' },
                                        { id: 'contract', name: 'Contract' },
                                    ]} fullWidth />
                                    <DateInput source="employment_start_date" fullWidth />
                                    <DateInput source="employment_end_date" fullWidth />
                                    <DateInput source="access_end_date" fullWidth helperText="Defaults to one week after the employment end date if not provided" />
                                    <SelectInput source="rate_type" choices={[
                                        { id: 'daily', name: 'Daily' },
                                        { id: 'hourly', name: 'Hourly' },
                                    ]} fullWidth />
                                    <TextInput source="rate" fullWidth />
                                    <TextInput source="holiday_rate" fullWidth />
                                    <TextInput source="box_rental" fullWidth />
                                    <TextInput source="vehicle_allowance" fullWidth />
                                    <TextInput source="start_time" fullWidth />
                                    <TextInput source="end_time" fullWidth />
                                    <BooleanInput source="per_diems" label="Per Diems" />
                                    <BooleanInput source="is_onboarded" label="Is Onboarded?" />

                                    <Box display="flex" justifyContent="space-between" width="100%">
                                        <Toolbar disableGutters={true}>
                                            <SaveButton
                                                saving={formProps.saving}
                                                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                            />
                                        </Toolbar>
                                    </Box>
                                </form>
                            </Box>

                            <Box flex={2} ml="1em">
                                <Typography variant="h6" gutterBottom>Users</Typography>
                                <ResourceContextProvider value="employment-users">
                                    <EmploymentUsersList id={formProps.record.id} />
                                </ResourceContextProvider>

                                <AddEmploymentUsers employmentId={formProps.record.id} />

                                <Typography variant="h6" gutterBottom>Update Requests</Typography>
                                <ResourceContextProvider value="employment-update-requests">
                                    <EmploymentUpdateRequestList id={formProps.record.id} />
                                </ResourceContextProvider>
                            </Box>

                        </Box>
                    </Box>
                )}
            />
        </Edit>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export const CreateEmployment = (props) => {
    const [value, setValue] = React.useState(0);
    const redirect = useRedirect();
    const notify = useNotify();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [mutate] = useMutation();
    const save = React.useCallback(
        async (values) => (
            mutate({
                type: 'create',
                resource: 'employees',
                payload: { data: values },
            }, { returnPromise: true })
                .then(res => {
                    notify('ra.notification.created', 'success');
                    redirect('/employees');
                    return res;
                })
                .catch(error => {
                    if (typeof error.body === 'object') {
                        return error.body;
                    }
                    throw error;
                })
        ),
        [mutate],
    );

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Bulk Import" />
                    <Tab label="Manual Entry" />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <ProductionEmploymentsImportForm {...props} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Create {...props} redirect="list" title="Create Employments">
                    <SimpleForm save={save}>
                        <TextInput source="first_name" />
                        <TextInput source="surname" />
                        <TextInput source="email" />
                        <ReferenceInput label="Department" source="department_id" reference="departments" perPage={100}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <TextInput source="job_title" />
                        <TextInput source="phone" />
                        <SelectInput source="contract_type" choices={[
                            { id: 'daily', name: 'Daily' },
                            { id: 'contract', name: 'Contract' },
                        ]} />
                        <DateInput source="employment_start_date" />
                        <DateInput source="employment_end_date" />
                        <SelectInput source="rate_type" choices={[
                            { id: 'daily', name: 'Daily' },
                            { id: 'hourly', name: 'Hourly' },
                        ]} />
                        <TextInput source="rate" />
                        <TextInput source="holiday_rate" />
                        <TextInput source="box_rental" />
                        <TextInput source="vehicle_allowance" />
                        <TextInput source="start_time" />
                        <TextInput source="end_time" />
                        <BooleanInput source="per_diems" label="Per Diems" defaultValue={false} />
                        <BooleanInput source="is_onboarded" label="Is Onboarded?" />
                    </SimpleForm>
                </Create>
            </TabPanel>
        </Box>
    );
}
