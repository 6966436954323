// in src/App.js
import * as React from "react";
import { Admin, Resource } from 'react-admin';
import basicRestProvider from './basicRestProvider';
import AuthProviderClass from './authProvider';
import customRoutes from './customRoutes';
import config from './config';
import { ProductionContext, ProductionProvider } from "./ProductionContext";

// Resources
import { EmploymentAttributeEdit } from "./resources/employment-attributes";
import { DepartmentList, DepartmentsCreate, DepartmentsEdit } from "./resources/departments";
import { TimesheetWeekEdit, TimesheetWeekList } from "./resources/timesheet-weeks";
import { TimesheetsList } from "./resources/timesheets";
import { CreateEmployment, EmploymentsEdit, EmploymentsList } from "./resources/employments";
import { EmploymentUpdateRequestList } from "./resources/employment-update-requests";
import { ProductionApproversList, ProductionApproversCreate, ProductionApproversEdit } from "./resources/production-approvers";
import { DepartmentApproversList, DepartmentApproversCreate, DepartmentApproversEdit  } from "./resources/department-approvers";
import { DepartmentApprovalStagesList, DepartmentApprovalStagesEdit, DepartmentApprovalStagesCreate } from "./resources/department-approval-stages";
import { ProductionApprovalStagesList, ProductionApprovalStagesEdit, ProductionApprovalStagesCreate } from "./resources/production-approval-stages";
import { TimesheetReportShow } from "./resources/timesheet-report";
import { EmploymentUsersList } from "./resources/employment-users";
import { LoginPage } from "./components/LoginPage";
import { LocationsCreate, LocationsEdit, LocationsList } from "./resources/locations";
import { GenerateTimesheetWeeksForm } from "./resources/timesheet-weeks-create";
import { FbLayout } from "./Layout";
import { i18nProvider } from "./i18nProvider";
import { UserList } from "./resources/users";
import HttpClient from "./components/HttpClient";
import { PenaltyTypesEdit, PenaltyTypesList } from "./resources/penalty-types";

const productionProvider = new ProductionProvider();
const authProvider = new AuthProviderClass(config.appUrl, productionProvider);
const httpClient = new HttpClient(config.apiUrl, productionProvider, authProvider);
const dataProvider = new basicRestProvider('', httpClient);

const App = () => (
    <ProductionContext.Provider value={productionProvider}>
        <Admin loginPage={LoginPage} customRoutes={customRoutes} dataProvider={dataProvider} authProvider={authProvider} layout={FbLayout} i18nProvider={i18nProvider}>
            <Resource name="departments" list={DepartmentList} edit={DepartmentsEdit} create={DepartmentsCreate} />
            <Resource name="locations" list={LocationsList} edit={LocationsEdit} create={LocationsCreate} />
            <Resource name="employment-attributes" edit={EmploymentAttributeEdit} />
            <Resource name="timesheet-weeks" list={TimesheetWeekList} edit={TimesheetWeekEdit} create={GenerateTimesheetWeeksForm} options={{label: "Timesheet Weeks"}} />
            <Resource name="timesheets" list={TimesheetsList} />
            <Resource name="employees" list={EmploymentsList} edit={EmploymentsEdit} create={CreateEmployment} options={{label: "Employments"}} />
            <Resource name="employment-update-requests" show={EmploymentUpdateRequestList} edit={EmploymentUpdateRequestList} options={{label: "update requests"}}  />
            <Resource name="employment-users" show={EmploymentUsersList} options={{label: "users"}} />
            <Resource name="production-approvers" options={{ label: 'Prod. Approvers' }} list={ProductionApproversList} edit={ProductionApproversEdit} create={ProductionApproversCreate} />
            <Resource name="production-approval-stages" options={{ label: 'Prod. Approval Stages' }} list={ProductionApprovalStagesList} edit={ProductionApprovalStagesEdit} create={ProductionApprovalStagesCreate} />
            <Resource name="department-approvers" options={{ label: 'Dept. Approvers' }} list={DepartmentApproversList} edit={DepartmentApproversEdit} create={DepartmentApproversCreate} />
            <Resource name="department-approval-stages" options={{ label: 'Dept. Approval Stages' }} list={DepartmentApprovalStagesList} edit={DepartmentApprovalStagesEdit} create={DepartmentApprovalStagesCreate} />
            <Resource name="timesheet-report" edit={TimesheetReportShow} />
            <Resource name="penalty-types" options={{label: 'Penalty Types'}} list={PenaltyTypesList} edit={PenaltyTypesEdit} />
            <Resource name="users" list={UserList} />
            <Resource name="settings" />
        </Admin>
    </ProductionContext.Provider>
);

export default App;
