import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { withStyles } from '@material-ui/core/styles';
import {Button, useDataProvider} from 'react-admin';
import { downloadGetFileResult } from '../../functions/downloadGetFileResult';

const styles = {
    button: {
        marginTop: '1em',
        marginBottom: '1em',
        width: '20%'
    }
};

const DownloadEmploymentsTemplateButton = ({ classes, record }) => {
    const dataProvider = useDataProvider();

    const downloadTemplate = () => dataProvider.getFile(
        `employments-template`
    ).then(downloadGetFileResult)

    return <Button
        className={classes.button}
        variant="contained"
        label="Download template"
        title="Download template"
        onClick={downloadTemplate}
    >
        <GetAppIcon/>
    </Button>
};

export default withStyles(styles)(DownloadEmploymentsTemplateButton);
