// in src/customRoutes.js
import * as React from "react";
import { Route } from 'react-router-dom';
import { AddNewEmploymentForm } from "./resources/production-admin/add-new-employment-form";
import { AddNewAttributeForm } from "./resources/productions/add-new-attribute-form";
import { ProductionSettingsScreen } from "./resources/settings";

const routes = [
    <Route exact path="/new-employment/:productionId" component={AddNewEmploymentForm} />,
    <Route exact path="/new-employment-attribute/:productionId" component={AddNewAttributeForm} />,

    <Route exact path="/settings" component={ProductionSettingsScreen} />,
];

export default routes;
