import * as React from "react";
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    SelectInput,
    ReferenceInput,
    required,
    BooleanInput
} from 'react-admin';

export const AddNewEmploymentForm = props => {
    const redirect = `/production-admin/${props.match.params.productionId}/show/employments`;

    const transform = data => {
        data.production_id = props.match.params.productionId;
        data.department_id = data.department.id;
        delete data.department;
        return data;
    };

    const { staticContext, ...rest } = props;
    return (
    <Create basePath="" resource="employments" {...rest} transform={transform}>
        <SimpleForm redirect={redirect}>
            <TextInput validate={[ required() ]} source="first_name" />
            <TextInput validate={[ required() ]} source="surname" />
            <ReferenceInput validate={[ required() ]} label="Department" source="department.id" reference="departments"
                filter={{ production_id: props.match.params.productionId }} perPage={100}>
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput validate={[ required() ]} source="job_title" />
            <TextInput source="email" />
            <TextInput validate={[ required() ]} source="phone" />
            <SelectInput validate={[ required() ]} source="contract_type" choices={[
                { id: 'daily', name: 'Daily' },
                { id: 'contract', name: 'Contract' },
            ]} />
            <DateInput validate={[ required() ]} source="employment_start_date" />
            <DateInput source="employment_end_date" />
            <TextInput validate={[ required() ]} source="rate" />
            <TextInput validate={[ required() ]} source="holiday_rate" />
            <TextInput source="box_rental" />
            <TextInput source="vehicle_allowance" />
            <SelectInput validate={[ required() ]} source="rate_type" choices={[
                { id: 'daily', name: 'Daily' },
                { id: 'hourly', name: 'Hourly' },
            ]} />
            <TextInput validate={[ required() ]} source="start_time" />
            <TextInput validate={[ required() ]} source="end_time" />
            <BooleanInput source="per_diems" label="Per Diems" defaultValue={false} />
        </SimpleForm>
    </Create>
    );
};
