import * as React from 'react';
import { useSelector } from 'react-redux';
import { Menu as RaMenu, DashboardMenuItem, MenuItemLink, getResources, useGetResourceLabel } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import { useLocation } from 'react-router-dom';

export const Menu = (props) => {
    const resources = useSelector(getResources);
    const getResourceLabel = useGetResourceLabel();
    const {hasDashboard} = props;
    const location = useLocation();

    return (
        <RaMenu {...props}>
            {hasDashboard && <DashboardMenuItem />}
            {resources.filter(r => r.hasList).map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={getResourceLabel(resource.name, 2)}
                    leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                />
            ))}
            {location.pathname === "/settings" && <MenuItemLink to="/settings" primaryText="Settings" leftIcon={<DefaultIcon />}/>}
        </RaMenu>
    );
};