import * as React from "react";
import {Edit, SimpleForm, ReferenceInput, SelectInput, BooleanInput} from 'react-admin';
import { FbDateRangeSelectInput } from "../components/FbDateField";
import { useProductionProvider } from "../ProductionContext";

export const ProductionSettingsScreen = props => {
    const { staticContext, ...rest } = props;
    const { production } = useProductionProvider();
    const timesheetPdfTemplates = [
        { id: 'standard', value: 'Standard', },
        { id: 'single_per_diem', value: 'Single Per Diem', },
    ];
    const transform = data => {
        // Blacklisted fields
        ['id', 'name', 'address', 'created_at', 'updated_at']
            .forEach((f) => { delete data[f] });
        return data;
    };

    return (
    <Edit basePath="" resource="settings" id={production.id} title="Settings" transform={transform} {...rest}>
        <SimpleForm redirect="/settings">
            <SelectInput source="timesheet_pdf_template" choices={timesheetPdfTemplates} optionText="value" optionValue="id" />
            <ReferenceInput label="Current Timesheet Week" source="current_timesheet_week_id" reference="timesheet-weeks" allowEmpty perPage={100}>
                <FbDateRangeSelectInput optionValue="id" startDateSource="start_date" endDateSource="end_date" extraSource="production_reference" />
            </ReferenceInput>
            <BooleanInput source="has_grace" label="Has Grace?" defaultValue={false} />
        </SimpleForm>
    </Edit>
    );
};
