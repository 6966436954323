import { stringify } from 'query-string';

class basicRestProvider {

    constructor(
        apiUrl,
        httpClient
    ) {
        this.apiUrl = apiUrl;
        this.httpClient = httpClient;
    }

    getList(resource, params) {
        const query = new URLSearchParams(params.filter);

        if (params.sort) {
            query.append('sort_by', params.sort.field);
            query.append('sort_order', params.sort.order);
        };

        if (params.pagination) {
            query.append('per_page', params.pagination.perPage);
            query.append('page', params.pagination.page);
        }

        const url = `${this.apiUrl}/${resource}?${query.toString()}`;

        return this.httpClient.fetchJson(url).then(({ headers, json }) => {
            return {
                data: json.data ?? json,
                total: json.meta?.total ?? json.total ?? json.length
            };
        });
    }

    getOne(resource, params) {
        if (resource === "settings")
            params.id = "";

        return this.httpClient.fetchJson(`${this.apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        }))
    }

    getMany(resource, params) {
        if (params.ids.length === 1) {
            return this.httpClient.fetchJson(`${this.apiUrl}/${resource}/${params.ids.pop()}`).then(({ json }) => ({
                data: [json],
            }));
        }

        const query = new URLSearchParams(params.filter);
        params.ids.forEach(id => query.append('id[]', id));
        const url = `${this.apiUrl}/${resource}?${query.toString()}`;
        return this.httpClient.fetchJson(url).then(({ json }) => ({ data: json }));
    }

    getManyReference(resource, params) {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
            sort: JSON.stringify([field, order]),
            perPage: perPage,
            page: page,
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${this.apiUrl}/${resource}?${stringify(query)}`;

        return this.httpClient.fetchJson(url).then(({ headers, json }) => {
            return {
                data: json.data,
                total: json.meta.total
            };
        });
    }

    update(resource, params) {
        if (resource === "settings")
            params.id = "";

        return this.httpClient.fetchJson(`${this.apiUrl}/${resource}/${params.id}`, {
            method: 'POST',
            body: JSON.stringify(params.data, (key, value) => {
                if (key === "id" || key === "created_at" || key === "updated_at") {
                    // Our API will never expect / accept these keys in the body
                    return undefined;
                }
                return value;
            }),
        }).then(({ json }) => ({ data: json }))
        .catch((error) => {
            if (error.message.match(/The POST method.*Supported.*PUT/)) {
                // just retry it... h4x central
                return this.httpClient.fetchJson(`${this.apiUrl}/${resource}/${params.id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data, (key, value) => {
                        if (key === "id" || key === "created_at" || key === "updated_at") {
                            // Our API will never expect / accept these keys in the body
                            return undefined;
                        }
                        return value;
                    }),
                }).then(({ json }) => ({ data: json }));
            }
            throw error;
        })
    }

    updateNested(resource, params) {
        return this.httpClient.fetchJson(`${this.apiUrl}/${params.nested_resource}/${params.nested_id}/${resource}/${params.id}`, {
            method: 'POST',
            body: JSON.stringify(params.data, (key, value) => {
                if (key === "id" || key === "created_at" || key === "updated_at") {
                    // Our API will never expect / accept these keys in the body
                    return undefined;
                }
                return value;
            }),
        }).then(({ json }) => ({ data: json }))
            .catch((error) => {
                if (error.message.match(/The POST method.*Supported.*PUT/)) {
                    // just retry it... h4x central
                    return this.httpClient.fetchJson(`${this.apiUrl}/${params.nested_resource}/${params.nested_id}/${resource}/${params.id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data, (key, value) => {
                            if (key === "id" || key === "created_at" || key === "updated_at") {
                                // Our API will never expect / accept these keys in the body
                                return undefined;
                            }
                            return value;
                        }),
                    }).then(({ json }) => ({ data: json }));
                }
                throw error;
            })
    }

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany(resource, params) {
        return Promise.all(
            params.ids.map(id =>
                this.httpClient.fetchJson(`${this.apiUrl}/${resource}/${id}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) }));
    }

    create(resource, params) {
        return this.httpClient.fetchJson(`${this.apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    }

    delete(resource, params) {
        return this.httpClient.fetchJson(`${this.apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'text/plain',
            }),
        }).then(({ json }) => ({ data: json }))
    }

    deleteNested(resource, params) {
        return this.httpClient.fetchJson(`${this.apiUrl}/${params.nested_resource}/${params.nested_id}/${resource}/${params.id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'text/plain',
            }),
        }).then(({ json }) => ({ data: json }))
    }

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany(resource, params) {
        return Promise.all(
            params.ids.map(id =>
                this.httpClient.fetchJson(`${this.apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                    headers: new Headers({
                        'Content-Type': 'text/plain',
                    }),
                })
            )
        ).then(responses => ({
            data: responses.map(({ json }) => json.id),
        }))
    }

    getFile(resource) {
        return this.httpClient.fetchBlob(`${this.apiUrl}/${resource}`).then((response) => {
            const filename = response.headers.get('Content-Disposition').split('filename=')[1].split(';')[0].replaceAll('"', '');
            return {
                data: new File([response.blob], filename, {'type': response.headers.get('Content-type')}),
                filename: filename
            };
        });
    }

    createMultipart(resource, params) {
        let formData = new FormData();
        for (var key in params.data) {
            if (typeof params.data[key].rawFile == "object") {
                formData.append(key, params.data[key].rawFile);
            } else {
                formData.append(key, params.data[key]);
            }
        }
        return this.httpClient.fetchJson(`${this.apiUrl}/${resource}`, {
            method: 'POST',
            body: formData,
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }))
    }
}

export default basicRestProvider;
