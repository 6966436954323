import * as React from "react";
import { Edit, EditButton, DeleteButton, SimpleForm, TextInput, AutocompleteInput, ReferenceInput, required, Toolbar, SaveButton, TextField, Datagrid, List, Create } from 'react-admin';

/** Toolbar that hides delete (use grid) */
const ProductionApproversEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const ProductionApproverTitle = props => {
    return <span>{Object.keys(props.record).length === 0 ? 'New Production Approver' : `${props.record.role_name} Stage - ${props.record.user.name}`}</span>;
};

export const ProductionApproversList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="role_name" label="Role" sortable={false} />
            <TextField source="user.name" label="Name" sortable={false} />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const ProductionApproversEdit = props => {

    const transform = data => {
        data.user_id = data.user.id;
        delete data.user;
        return data;
    };

    return <Edit {...props} title={<ProductionApproverTitle />} undoable={false} transform={transform}>
        <SimpleForm toolbar={<ProductionApproversEditToolbar />} redirect="list">
            <TextInput source="role_name" label="Role" />
            <ReferenceInput label="User" source="user_id" reference="users" validate={[ required() ]}>
                <AutocompleteInput optionText="name" suggestionLimit="5" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
};

export const ProductionApproversCreate = props => {

    const transform = data => {
        data.production_id = props.match.params.productionId;
        data.user_id = data.users.id;
        delete data.users;
        return data;
    };

    return <Create {...props} title={<ProductionApproverTitle />} transform={transform}>
        <SimpleForm toolbar={<ProductionApproversEditToolbar />} redirect="list">
            <TextInput source="role_name" label="Role" />
            <ReferenceInput label="User" source="users.id" reference="users" validate={[ required() ]}>
                <AutocompleteInput optionText="name" suggestionLimit="5" />
            </ReferenceInput>
        </SimpleForm>
    </Create>
};
