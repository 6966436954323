import { Title } from "react-admin";
import { TableCell, TableRow, createStyles, withStyles } from '@material-ui/core';
import { createElement, useState } from "react";
import { Button } from 'ra-ui-materialui';
import DownloadIcon from '@material-ui/icons/CloudDownload';

const DownloadButton = ({ record }) => {

    const downloadLink = (e) => {
        const url = record.pdf_url;
        window.open(url, '_blank');
    }

    return (
        <Button onClick={downloadLink}><DownloadIcon /></Button>
    );
};

/**
 * These styles output a 0px, 3px, 5px
 */
const tableRowWidthDepthIndicatorStyles = () => createStyles({
    'TableRowDepth': {
        '&::before': {
            'content': '""',
            'position': 'absolute',
            'height': '100%',
            'display': 'block',
            'zIndex': '5'
        },
        '& td': {
            'backgroundClip': 'padding-box'
        },
        '& .MuiTableCell-head': {
            'fontWeight': '700'
        },
        'position': 'relative',
    },
    'TableRowDepth0': {
        '&::before': {
            'width': '0px'
        },
        '& td': {
            'backgroundColor': 'none',
        }
    },
    'TableRowDepth1': {
        '&::before': {
            'width': '3px',
            'backgroundColor': '#b1b1b1'
        },
        '& td': {
            'backgroundColor': '#f1f1f1',
        }
    },
    'TableRowDepth2': {
        '&::before': {
            'width': '7px',
            /* Simulate a bit of depth for the inner rows */
            'background': 'linear-gradient(to right, #b1b1b1 2px, #ececec 3px, #9a9a9a 5px)'
        },
        '& td': {
            'backgroundColor': '#ececec',
        }
    }
});

const TableRowWithDepthIndicator = withStyles(tableRowWidthDepthIndicatorStyles)(({classes, ...props}) => (
    <TableRow className={classes['TableRowDepth'] + ' ' + classes['TableRowDepth' + props.depth]} {...props} />
));

// TODO Penalties differ per production
export const WeeklyReportHeader = ({groupBy, depth, record, showFullTimesheet}) => (<>
    {(depth === 0 && record && <Title title={`Weekly Report ${record.start_date} to ${record.end_date}`}></Title>)}
    <TableRowWithDepthIndicator depth={depth}>
        { groupBy === 'timesheet' && (<TableCell variant="head" colSpan={ showFullTimesheet ? 1 : 2 }>Employee</TableCell>)}
        { (showFullTimesheet || groupBy === 'status') && (<TableCell variant="head">Status</TableCell>)}
        { (showFullTimesheet || groupBy === 'department') && (<TableCell variant="head">Department</TableCell>)}
        { (showFullTimesheet || groupBy === 'approver') && (<TableCell variant="head">Approver</TableCell>)}
        { groupBy !== 'timesheet' && (<TableCell variant="head"># Sheets</TableCell>)}
        <TableCell variant="head">Hours</TableCell>
        <TableCell variant="head">Pens.</TableCell>
        <TableCell variant="head">Tot. Mileage</TableCell>
        <TableCell variant="head">OT &lt; 11</TableCell>
        <TableCell variant="head">OT &gt; 11</TableCell>
        <TableCell variant="head">B.T.</TableCell>
        <TableCell variant="head">Night Work</TableCell>
        <TableCell variant="head">Travel</TableCell>
        <TableCell variant="head">C. Meal</TableCell>
        <TableCell variant="head">D. Meal</TableCell>
        <TableCell variant="head">Early</TableCell>
        <TableCell variant="head"></TableCell>
    </TableRowWithDepthIndicator>
</>);

export const WeeklyReportRow = ({groupBy, record, expand, depth, showFullTimesheet}) => {
    const [expanded, setExpanded] = useState(false);

    return (<>
    <TableRowWithDepthIndicator onClick={() => setExpanded(!expanded)} depth={depth}>
        { groupBy === 'timesheet' && (<TableCell colSpan={ showFullTimesheet ? 1 : 2 }>{record.employee_name}</TableCell>) }
        { (showFullTimesheet || groupBy === 'status') && (<TableCell>{record.status}</TableCell>) }
        { (showFullTimesheet || groupBy === 'department') && (<TableCell>{record.department}</TableCell>) }
        { (showFullTimesheet || groupBy === 'approver') && (<TableCell>{record.approver_id ? record.approver : (record.approver ? '' : record.status)}</TableCell>)}
        { groupBy !== 'timesheet' && (<TableCell>{record.num_sheets}</TableCell>) }

        <TableCell>{record.hours}</TableCell>
        <TableCell>{record.penalties}</TableCell>
        <TableCell>{record.total_mileage}</TableCell>
        <TableCell>{record.overtime}</TableCell>
        <TableCell>{record.overtime_after_11pm}</TableCell>
        <TableCell>{record.broken_turnaround}</TableCell>
        <TableCell>{record.night_work}</TableCell>
        <TableCell>{record.travel}</TableCell>
        <TableCell>{record.curtailed_meal}</TableCell>
        <TableCell>{record.delayed_meal}</TableCell>
        <TableCell>{record.early_call}</TableCell>
        <TableCell>{!record.approver_id && (<DownloadButton record={record} />)}</TableCell>
    </TableRowWithDepthIndicator>
    {expanded && typeof expand !== 'undefined' && record.class.startsWith('summary') === true && createElement(expand, {record})}
    </>)
}
