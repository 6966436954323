import * as React from "react";
import {
    List,
    Datagrid,
    TextField,
    SelectInput,
    useDataProvider,
    useNotify,
    useRefresh,
    Button,
    SimpleForm,
    Create,
    TextInput,
    useCreateSuggestionContext,
    AutocompleteInput,
    ReferenceInput,
    required
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from "react-final-form";
import { useState, useCallback } from 'react';
import { Dialog } from '@material-ui/core';
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
    root: { width: 600, margin: '30px auto' },
    spacer: { height: 20 },
    table: { margin: '10px 0' },
    saveButton: { marginLeft: '30px' },
    button: { margin: '1em auto' },
    form: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
    deleteButton: { color: '#f44336 !important', backgroundColor: 'transparent !important' }
});

const RemoveEmploymentUserButton = ({ record }) => {

    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();

    const dataProvider = useDataProvider();

    const removeEmploymentUser = async () => {
        console.log('removeEmploymentUser');
        console.log(record);

        dataProvider.deleteNested(
            `employment-users`, {id: record.id, nested_resource: 'employments', nested_id: record.pivot.employment_id }
        ).then((response) => {
            console.log(response)
            notify(response.data.message, 'success');
            refresh();
        }).catch((response) => {
            if (typeof response.body !== 'undefined' && response.body.message) {
                notify(response.body.message, 'error');
            } else if (typeof response.body !== 'undefined') {
                notify(JSON.stringify(response.body), 'error'); // lazy
            } else {
                console.error(response);
            }
        })
    };

    return <Button
        className={classes.deleteButton}
        size="small"
        label="Delete"
        title="Delete"
        // prevent event propagation in order to not expand the parent row of the button element when clicked
        onClick={ (e) => { e.stopPropagation(); removeEmploymentUser() } }
    >
        <DeleteIcon/>
    </Button>

}

export const EmploymentUsersList = props => (
    <List
        {...props}
        bulkActionButtons={false}
        actions={false}
        exporter={false}
        perPage={5}
        sort={{ field: 'access_level', order: 'desc' }}
        basePath="employment-users"
        resource="employment-users"
        filter={{ employment_id: props.id }}
        title={<></>}
        pagination={false}
    >
        <Datagrid rowClick="expand" expand={<ShowEmploymentUser />}>
            <TextField source="name" label="User" />
            <TextField source="email" label="E-mail" />
            <TextField source="pivot.access_level" label="Access Level" />
            <RemoveEmploymentUserButton></RemoveEmploymentUserButton>
        </Datagrid>
    </List>
);

const ShowEmploymentUser = props => {
    const classes = useStyles();
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    let formData = {
        access_level: props.record.pivot.access_level,
        employment_id: props.record.pivot.employment_id,
    };

    const onSubmit = async (values) => {
        console.log('onSubmit');
        console.log(JSON.stringify(values, 0, 2));

        console.log(props.record);

        dataProvider.updateNested(
            `employment-users`,
            { id: props.record.id, nested_resource: 'employments', nested_id: props.record.pivot.employment_id, data: {access_level: values.access_level} }
        ).then((response) => {
            notify('Access level updated', 'success');
            refresh();
        }).catch((response) => {
            if (typeof response.body !== 'undefined' && response.body.message) {
                notify(response.body.message, 'error');
            } else if (typeof response.body !== 'undefined') {
                notify(JSON.stringify(response.body), 'error'); // lazy
            } else {
                console.error(response);
            }
        })

    };

    const renderForm = ({handleSubmit, submitting}) => (
        <form onSubmit={handleSubmit} className={classes.form}>
            <SelectInput source="access_level" choices={[
                { id: 'agent', name: 'Agent' },
                { id: 'employee', name: 'Employee' },
            ]} helperText={false} validate={required()} />
            <button type="submit" disabled={submitting}
                    className={`${classes.saveButton} MuiButtonBase-root MuiButton-root MuiButton-contained RaSaveButton-button-72 makeStyles-saveButton-164 MuiButton-containedPrimary MuiButton-containedSizeSmall MuiButton-sizeSmall`}>
                <span className="MuiButton-label">
                    Update
                </span>
            </button>
        </form>
    );

    return <Form onSubmit={onSubmit} initialValues={formData} render={renderForm} />;
};

const CreateNewUser = ({employmentId}) => {
    const { filter, onCancel } = useCreateSuggestionContext();
    const refresh = useRefresh();

    const onSuccess = () => {
        refresh();
    }

    const record = {
        employment_id: employmentId,
        email: React.useMemo(() => filter, [])
    };

    return (
        <Dialog maxWidth='xs' open onClose={onCancel}>
            <Create resource="employment-users" basePath="" title=" " onSuccess={onSuccess} record={record}>
                <SimpleForm redirect={false}>
                    <TextInput
                        source="first_name"
                        autoFocus
                    />
                    <TextInput
                        source="last_name"
                    />
                    <TextInput
                        source="email"
                    />
                    <SelectInput source="access_level" choices={[
                        { id: 'agent', name: 'Agent' },
                        { id: 'employee', name: 'Employee' },
                    ]} />
                </SimpleForm>
            </Create>
        </Dialog>
    );
};

export const AddEmploymentUsers = ({employmentId}) => {
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles();
    const refresh = useRefresh();

    const toggleExpanded = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded])

    const transform = (data) => {
        data.employment_id = employmentId;
        return data;
    }

    const renderUser = (record) => {
        if (!record) {
            return "";
        }
        if (record.id === "@@ra-create") {
            return "Create new user";
        }
        return `${record.first_name} ${record.last_name} (${record.email})`
    }

    return (<>
        <Button
            className={classes.button}
            variant="contained"
            label="Add another"
            title="Add another"
            onClick={toggleExpanded}
            />

        {expanded && <>
            <Create resource="employment-users" basePath="" transform={transform} onSuccess={refresh} className={classes.table} title=" ">
                <SimpleForm>
                    <ReferenceInput label="Users" source="user_id" reference="users" validate={required()}>
                        <AutocompleteInput
                            shouldRenderSuggestions={(val) => { return val.trim().length > 2 }}
                            optionText={renderUser}
                            create={<CreateNewUser employmentId={employmentId} />}
                        />
                    </ReferenceInput>
                    <SelectInput source="access_level" choices={[
                        { id: 'agent', name: 'Agent' },
                        { id: 'employee', name: 'Employee' },
                    ]} validate={required()} />
                </SimpleForm>
            </Create>
        </>}
    </>);
};
