import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';

const englishMessagesWithUpdates = {
    ra: {
        ...englishMessages.ra,
        auth: {
            ...englishMessages.ra.auth,
            logout: 'Back to App',
        },
    }
};
export const i18nProvider = polyglotI18nProvider(locale =>
    englishMessagesWithUpdates,
    'en' // Default locale
);
