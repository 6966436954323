import * as React from "react";
import {TextField, Datagrid, List, BooleanField, TextInput} from 'react-admin';

const filters = [
    <TextInput label="Search" source="q" alwaysOn />,
];

export const UserList = props => (
    <List {...props} filters={filters}>
        <Datagrid>
            <TextField source="first_name" />
            <TextField source="last_name" />
            <TextField source="email" />
            <BooleanField looseValue={true} source="is_admin" sortable={false} />
            <BooleanField looseValue={true} source="is_department_admin" sortable={false} />
            <BooleanField looseValue={true} source="is_employee" sortable={false} />
            <BooleanField looseValue={true} source="is_prod_approver" sortable={false} />
            <BooleanField looseValue={true} source="is_dept_approver" sortable={false} />
        </Datagrid>
    </List>
);
