class HttpError extends Error {
    constructor(
        message,
        status,
        body = null
    ) {
        super(message);
        this.status = status;
        this.body = body;

        Object.setPrototypeOf(this, HttpError.prototype);
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = new Error(message).stack;
        }
        this.stack = new Error().stack;
    }
}

export default class HttpClient
{
    constructor(apiUrl, productionProvider, authProvider) {
        this.apiUrl = apiUrl;
        this.productionProvider = productionProvider;
        this.authProvider = authProvider;
    }

    createHeadersFromOptions(options) {
        const requestHeaders = (options.headers ||
            new Headers({
                Accept: 'application/json',
            })
        );

        if (
            !requestHeaders.has('Content-Type') &&
            !(options && (!options.method || options.method === 'GET')) &&
            !(options && options.body && options.body instanceof FormData)
        ) {
            requestHeaders.set('Content-Type', 'application/json');
        }
        if (this.authProvider.token) {
            requestHeaders.set('Authorization', `Bearer ${this.authProvider.token}`);
        }

        return requestHeaders;
    }

    fetch(url, options = {}) {
        if (typeof this.productionProvider.production !== "object") {
            this.authProvider.logout();
        }
        const scopedUrl = `${this.apiUrl}/v1/productions/${this.productionProvider.production.id}${url}`;
        const requestHeaders = this.createHeadersFromOptions(options);
        return fetch(scopedUrl, { ...options, headers: requestHeaders }).then(response => {
            if (response.status < 200 || response.status >= 300) {
                return response.json().then(json => Promise.reject(
                    new HttpError(
                        (json && json.message) || response.statusText,
                        response.status,
                        json
                    )
                ));
            }
            return response;
        })
    }

    fetchJson(url, options) {
        return this.fetch(url, options)
        .then(response => response.json().then(json => ({
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            json: json
        })))
    }

    fetchBlob(url, options) {
        return this.fetch(url, options)
        .then(response => response.blob().then(blob => ({
            status: response.status,
            statusText: response.statusText,
            headers: response.headers,
            blob: blob
        })))
    }
}