import * as React from "react";
import { Edit, SimpleForm, TextInput, List, Datagrid, TextField, DateField, Create } from 'react-admin';

const LocationsTitle = props => {
    return <span>{props.record ? `${props.record.name}` : ''}</span>;
};

export const LocationsList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <DateField source="created_at" />
        </Datagrid>
    </List>
);

export const LocationsEdit = props => (
    <Edit {...props} title={<LocationsTitle />} undoable={false}>
        <SimpleForm redirect="list">
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export const LocationsCreate = props => (
    <Create {...props} title={<LocationsTitle />} undoable={false} redirect="list">
        <SimpleForm redirect="list">
            <TextInput source="name" />
        </SimpleForm>
    </Create>
);