
import config from '../config';
import { useAuthProvider, useLogout, useRedirect } from 'react-admin';
import React from 'react';

export const LoginPage = props => {
    let authProvider = useAuthProvider();
    let redirect = useRedirect();
    let logout = useLogout();

    // When our iframe loads, post a Message event to it asking for credentials
    // It is important to pass the app URL for cross-origin verification
    let sendCredentialRequest = (e) => {
        e.target.contentWindow.postMessage(
            'getCredentials',
            config.appUrl
        );
    }

    // When the iframe receives the message event, Angular will grab a handle to
    // our Window object and sent it a Message event. Here we set up a listener
    // during the effect stage of the component, and return a destructor which
    // will remove the listener, as we will no longer want it after navigating
    // away.
    React.useEffect(() => {
        window.addEventListener('message', handleCallback);

        // Rendering the Login page component should cause the user to be logged
        // out - this ensures that we don't keep old / not relevant production
        // data around between visits. We will shortly log them back in in the
        // callback handler.
        logout({});

        // on cleanup of component, remove the event listener
        return () => {
            window.removeEventListener('message', handleCallback);
        };
    }, []);

    // This is our listener function. Check the event origin hasn't changed and
    // put the token and production data into our contexts.
    const handleCallback = (e) => {
        if (e.origin === config.appUrl) {
            if (typeof e.data.error !== "undefined") {
                redirect(config.appUrl + '/');
                return;
            }
            if (typeof e.data.authToken === "string" && e.data.authToken.length) {
                if (e.data.selectedProduction === null || typeof e.data.selectedProduction !== 'object') {
                    // Must select a production first to access admin
                    redirect(config.appUrl + '/');
                    return;
                }
                authProvider.login({
                    token: e.data.authToken,
                    production: e.data.selectedProduction
                });
                redirect('/');
                return;
            }
            // If we received a message without an auth token or a current
            // production, redirect back to the app. Let them choose one there
            // before coming back here.
            redirect(config.appUrl);
        }
    }

    // TODO: Maybe set a timeout here which triggers an error message to be rendered.
    // At the moment if there is some issue, we will just stay on a white screen.
    return (
        <iframe
            style={{ display: "none" }}
            title="credential-service"
            src={`${config.appUrl}/credential-service`}
            onLoad={sendCredentialRequest} />
    );
};