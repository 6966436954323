import * as React from "react";
import { Edit, SimpleForm, TextInput, Toolbar, SaveButton, TextField, Datagrid, List, DateField, Create } from 'react-admin';

/** Toolbar that hides delete (use grid) */
const DepartmentsEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const DepartmentTitle = props => {
    return <span>Department - {props.record ? `${props.record.name}` : ''}</span>;
};

export const DepartmentList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" sortable={false} />
            <DateField source="created_at" sortable={false} />
        </Datagrid>
    </List>
);

export const DepartmentsEdit = props => {
    const transform = (record) => {
        delete record.access_level;
        return record;
    };

    return <Edit {...props} title={<DepartmentTitle />} undoable={false} transform={transform}>
        <SimpleForm toolbar={<DepartmentsEditToolbar />} redirect="list">
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
};

export const DepartmentsCreate = props => (
    <Create {...props} title={<DepartmentTitle />} undoable={false}>
        <SimpleForm toolbar={<DepartmentsEditToolbar />} redirect="list">
            <TextInput source="name" />
        </SimpleForm>
    </Create>
);
