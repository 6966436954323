import * as React from "react";
import { Edit, EditButton, DeleteButton, SimpleForm, NumberInput, ReferenceInput, SelectInput, required, Toolbar, SaveButton, TextField, Datagrid, List, Create, ReferenceField } from 'react-admin';
import { useFormState } from 'react-final-form';

/** Toolbar that hides delete (use grid) */
const DepartmentApprovalStagesEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const DepartmentApprovalStageTitle = props => {
    return <span>{Object.keys(props.record).length === 0 ? 'New Approval Stage':  `Stage ${props.record.stage}`}</span>;
};

export const DepartmentApprovalStagesList = props => {

    const filters = [
        <ReferenceInput alwaysOn source="department_id" reference="departments" perPage={100}>
            <SelectInput label="Department" source="id" optionText="name" />
        </ReferenceInput>,
    ];

    return <List {...props} filters={filters}>
        <Datagrid rowClick="edit">
            <ReferenceField source="department_id" reference="departments">
                <TextField label="Department" source="name" />
            </ReferenceField>
            <TextField source="stage" label="Stage #" />
            <TextField source="approver.role_name" label="Responsible Role" />
            <TextField source="approver.user.name" label="Responsible Person" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
};

export const DepartmentApprovalStagesEdit = props => {
    
    const transform = data => {
        data.department_approver_id = data.approver.id;
        delete data.approver;
        delete data.department;
        return data;
    };

    return <Edit {...props} title={<DepartmentApprovalStageTitle />} undoable={false} transform={transform}>
        <SimpleForm toolbar={<DepartmentApprovalStagesEditToolbar />} redirect="list">
            <ReferenceInput
                label="User"
                source="approver.id"
                reference="department-approvers"
                defaultValue={props.id}
                validate={[ required() ]}
            >
                <SelectInput optionText={ (record) => `${record.user.name} (${record.role_name})` } />
            </ReferenceInput>
            <NumberInput label="Stage" source="stage" validate={[ required() ]}/>
        </SimpleForm>
    </Edit>
};

const DepartmentInput = props => {
    const { values } = useFormState();

    return (
        <ReferenceInput
            label="Department"
            source="department.id"
            reference="departments"
            validate={[required()]}
            perPage={100}
        >
            <SelectInput optionText="name" />
        </ReferenceInput>
    );
}

const ApproverInput = props => {
    const { values } = useFormState();


    let departmentId = '';

    if (values.hasOwnProperty('department')) {
        departmentId = values.department.id;
    }

    return (
        <ReferenceInput
            label="User"
            source="approver.id"
            reference="department-approvers"
            validate={[required()]}
            filter={{ department_id: departmentId }}
        >
            <SelectInput {...props} optionText={ (record) => `${record.user.name} (${record.role_name})` } />
        </ReferenceInput>
    );
}

export const DepartmentApprovalStagesCreate = props => {

    const transform = data => {
        data.department_id = data.department.id;
        data.department_approver_id = data.approver.id;
        delete data.department;
        delete data.approver;
        return data;
    };

    return <Create {...props} title={<DepartmentApprovalStageTitle/>} transform={transform}>
        <SimpleForm toolbar={<DepartmentApprovalStagesEditToolbar/>} redirect="list">
            <DepartmentInput></DepartmentInput>
            <ApproverInput></ApproverInput>
            <NumberInput label="Stage" source="stage" validate={[required()]}/>
        </SimpleForm>
    </Create>
};
